import React, {useEffect, useRef, useState} from 'react';
import {Button, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import baseApi from "../../../apis/baseApi";
import {Paginated} from "../../../types";
import TableHeader from "../../../components/table/TableHeader";
import EmptyTr from "../../../components/table/rows/EmptyTr";
import {TimeSlot} from "../../Schedules";
import {format, parseISO, parse, isBefore, intervalToDuration, formatDuration} from "date-fns";
import {formatDateTime} from "../../../utils/dateFormatters";
import TableFooter from "../../../components/table/TableFooter";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useSelector} from "react-redux";
import {StoreState} from "../../../reducers";
import {faTrashAlt} from "@fortawesome/free-regular-svg-icons";
import DeleteModal from "../../../components/modals/DeleteModal";
import {es} from "date-fns/locale";
import {Member} from "../index";
import {Branch} from "../../../actions";


interface Reservation {
    id: string
    assistance: boolean
    cancelled: boolean
    created_on: string
    cancelled_on?: string
    timeslot: TimeSlot
    seat: string
    membership_plan?: string
    created_by_email?: string
    assistance_by_email?: string
    cancelled_by_email?: string
}

type UserRsvpProps = {
    member: Member
    membershipId?: string
}

const MemberRsvp = ({member, membershipId}: UserRsvpProps) => {
    const branches = useSelector<StoreState, Branch[]>(state => state.branches.branches)
    const [reservations, setReservations] = useState<Reservation[]>([])
    const count = useRef(0)
    const [showDelete, setShowDelete] = useState(false);
    const [selectedRsvp, setSelectedRsvp] = useState<Reservation>();
    const cancelAllowed = useSelector<StoreState, boolean>(state => state.staffUser.permissions.find(p => p.feature === "reservations")?.delete ?? false)

    useEffect(() => {
        let url = `/rsvp/user/?member=${member.id}`
        if (membershipId) url += `&membership=${membershipId}`
        baseApi.get<Paginated>(url).then((resp) => {
            count.current = resp.data.count
            setReservations(resp.data.results.map(r => {
                let start = parseISO(r.timeslot.start)
                if (r.timeslot.start_time !== null) {
                    start = parse(r.timeslot.start_time, "HH:mm:ss", start)
                }
                return ({
                    ...r,
                    timeslot: {...r.timeslot, start: start}
                });
            }))
        })
    }, [member])

    const getCancelledOnIntervalText = (rsvp: Reservation) => {
        if (!rsvp.cancelled_on) return <React.Fragment/>
        const duration = intervalToDuration({
            start: rsvp.timeslot.start,
            end: parseISO(rsvp.cancelled_on!)
        })
        const durationText = formatDuration(
            duration,
            {
                locale: es,
                format: ['months', 'days', 'hours', 'minutes'],
            },
        )
        return <span>Cancelada por <span className="text-primary">{rsvp.cancelled_by_email ?? '?'}</span> <span
            className="font-weight-bold"> {durationText} </span> antes de la clase.</span>
    }

    const handlePageChange = (page: number) => {
        let url = `/rsvp/user/?member=${member.id}&page=${page}`
        if (membershipId) url += `&membership=${membershipId}`
        baseApi.get<Paginated>(url).then((resp) => {
            setReservations(resp.data.results.map(r => ({
                ...r,
                timeslot: {...r.timeslot, start: parseISO(r.timeslot.start)}
            })))
        }).catch((err) => alert("ERROR"))
    }

    const renderRow = (rsvp: Reservation) => {
        return <tr
            className={`font-size-lg  align-middle text-left ${rsvp.cancelled ? "bg-transparent" : ""}`}
            key={rsvp.id}>
            {/* COL 1 */}
            <td>
                <div className="d-inline-flex">
                    {format(rsvp.timeslot.start, "dd 'de' MMM 'de' yyyy")} <span
                    className='text-primary'>{format(rsvp.timeslot.start, "H:mm")}</span>
                    <div  className="font-size-sm text-muted badge badge-pill badge-light  py-1 px-2 ml-2" hidden={branches.length === 1 || membershipId !== undefined}>
                        {rsvp.timeslot.branch?.substring(0, 12)}
                    </div>
                </div>

                <div className='font-size-sm text-muted '>
                    {rsvp.timeslot.studio_class.name}

                </div>
                <div className='mb-1'>
                    {rsvp.cancelled
                        ? <div className="font-size-">
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id={rsvp.id}>{getCancelledOnIntervalText(rsvp)}</Tooltip>}
                            >
                                <div
                                    className='label label-inline label-light-danger font-weight-bold font-size-lg label-lg'>
                                    <span>Cancelada el: </span>
                                    <span className="text-muted ml-1">
                                        <span className='text-danger'><u>{formatDateTime(rsvp.cancelled_on)}</u></span>
                                    </span>
                                </div>
                            </OverlayTrigger>

                            {/*{renderCancelledOnTooltip(rsvp)}*/}
                        </div>
                        : rsvp.assistance ? "" : ""
                    }
                </div>
            </td>

            {/* COL 2 */}
            <td>
                <div className='text-center'>{rsvp.seat}</div>
            </td>
            <td hidden={membershipId !== undefined} className='text-center font-size-sm'>
                {formatDateTime(rsvp.created_on)}
                <div className="font-size-xs">Por: {rsvp.created_by_email}</div>
            </td>
            <td hidden={membershipId !== undefined} className="text-center font-size-sm">
                <div hidden={ !rsvp.membership_plan } className="">
                    <span
                        className='label label-inline label-light-primary font-weight-bolder font-size'>{rsvp.membership_plan}</span>

                </div>
            </td>
            {/* COL3 */}
            <td className='text-center'>
                {rsvp.cancelled ?
                    <div className="font-size-xs">
                        <div className='align-items-center '>
                            <span
                                className='label label-inline label-light-danger font-weight-bolder font-size'>CANCELADA</span>
                            <div className="">
                                {/* <FontAwesomeIcon icon={faClock} className=" font-size-sm mr-1 text-muted"/> */}
                                <span
                                    className='font-weight-bold text-muted font-size-xs'>{rsvp.cancelled_by_email}</span>
                            </div>
                        </div>
                    </div> : rsvp.assistance ? <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id={rsvp.id + "assist"}>{<span>Asistencia confirmada por <span
                                className="text-primary">{rsvp.assistance_by_email}</span></span>}</Tooltip>}>
                        <span
                            className='label label-inline label-light-success font-weight-bolder'>ASISTIÓ</span>

                        </OverlayTrigger>
                        : <span className='label label-inline text-muted font-weight-bolder font-size-lg '>SIN ASISTENCIA</span>}
            </td>
            <td>
                {isBefore(new Date(), rsvp.timeslot.start) && !rsvp.cancelled ?
                    <div>
                        <Button
                            disabled={!cancelAllowed}
                            className="btn btn-icon btn-light btn-xs btn-hover-danger mr-1"
                            onClick={() => {
                                setSelectedRsvp(rsvp)
                                setShowDelete(true)
                            }}>
                            <FontAwesomeIcon icon={faTrashAlt}/>
                        </Button>
                    </div> : <React.Fragment/>
                }
            </td>
        </tr>
    }

    return (
        <div>
            <DeleteModal
                url={`/rsvp/update/${selectedRsvp?.id}/`}
                onSuccess={() => {
                    setReservations(
                        reservations.map(r => r.id === selectedRsvp!.id ? {
                            ...selectedRsvp!,
                            cancelled: true,
                            cancelled_on: new Date().toISOString()
                        } : r)
                    )
                }}
                show={showDelete}
                onHide={() => {
                    setShowDelete(false);
                    setSelectedRsvp(undefined)
                }}
                text={"Cancelar Reservación"}
                successText={"Reservación cancelada correctamente."}
            >
                {!selectedRsvp ? <React.Fragment/> :
                    <div>
                        <p className="p-0 m-0">Cliente: {member.full_name} {member.last_name ?? ''} ({member.external_id})</p>
                        <p className="p-0 m-0">Clase: {selectedRsvp!.timeslot.studio_class.name}</p>
                        <p className="p-0 m-0">Horario: {format(selectedRsvp!.timeslot.start, "dd/MMM/yyyy h:mm a", {locale: es})}</p>
                    </div>
                }
            </DeleteModal>
            <Table hover>
                <TableHeader headers={membershipId === undefined ? ["FECHA", "SPOT", "CREADO EN", "MEMBRESÍA", "CHECK-IN"] : ["FECHA", "SPOT", "CHECK-IN"]}/>
                <tbody>
                {reservations.length === 0 ? <EmptyTr/> : reservations.map((rsvp) => renderRow(rsvp))}
                </tbody>
            </Table>
            <TableFooter count={count.current} pageCount={reservations.length} pageSize={10}
                         onPageChange={handlePageChange}/>
        </div>
    );
};

export default MemberRsvp;
