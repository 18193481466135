import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Formik, Form as FormikForm, Field} from "formik";
import {Alert, Button, Col, Row} from "react-bootstrap";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {Branch, BranchesState, removeBranch} from "../../../actions";
import {StoreState} from "../../../reducers";
import baseApi from "../../../apis/baseApi";
import {setStudio, Studio} from "../../../actions/studio";
import ToolboxHeader from "../ToolboxHeader";
import ImageField from "../../../components/form/ImageField";
import FormInputGroup from "../../../components/form/FormInputGroup";
import CreateEditBranchModal from './CreateEditBranchModal';
import DeleteModal from "../../../components/modals/DeleteModal";
import yup from '../../../utils/yup';
import FieldError from "../../../components/form/FieldError";

const Branches = () => {
    const dispatch = useDispatch()
    const studio = useSelector<StoreState, Studio>(state => state.studio)
    const branchesState = useSelector<StoreState, BranchesState>(state => state.branches)

    const [showCreate, setShowCreate] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [img, setImg] = useState<null | File>(null);
    const [coverImg, setCoverImg] = useState<null | File>(null);
    const [editName, setEditName] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState<Branch>();
    const [success, setSuccess] = useState<boolean>();

    const ValidationSchema = yup.object().shape({
        whatsapp: yup.string().min(8),
        preferences: yup.object().shape({
            terms_and_conditions: yup.string().url("Ingresa un URL valido")
        })
    })

    const renderAlert = () => {
        if (success === undefined) return <React.Fragment/>
        return <Alert className="mt-2"
                      variant={success ? "success" : "danger"}>{success ? "Estudio actualizado correctamente." : "Error"}</Alert>
    }

    if (!studio.id) return <React.Fragment/>
    return (
        <div style={{overflowY: "auto"}} className="p-4">
            <CreateEditBranchModal show={showCreate} onHide={() => {
                setShowCreate(false)
                setSelectedBranch(undefined)
            }} branch={selectedBranch}/>
            <DeleteModal url={`/studios/branch/${selectedBranch?.id}/`} onSuccess={() => {
                dispatch(removeBranch(selectedBranch?.id || ""))
            }} show={showDelete} onHide={() => {
                setShowDelete(false)
                setSelectedBranch(undefined)
            }}>
                <p>¿Estas seguro que quieres eliminar la sucursal <strong>{selectedBranch?.name}</strong></p>
            </DeleteModal>
            <ToolboxHeader title="Mi Estudio"/>
            <Row className="border-top">
                <Col className="border-right px-15">
                    <Formik
                        enableReinitialize
                        validationSchema={ValidationSchema}
                        initialValues={{
                            name: studio.name,
                            instagram: studio.instagram,
                            whatsapp: studio.whatsapp,
                            preferences: {
                                terms_and_conditions: studio.preferences.terms_and_conditions
                            },
                        }}
                        onSubmit={(values, {setSubmitting}) => {
                            setSuccess(undefined)
                            if (img) {
                                const formData = new FormData();
                                formData.append("image", img, img.name);
                                baseApi.patch('/studios/', formData).then(resp => {
                                    dispatch(setStudio({...studio, ...resp.data}))
                                })
                            }
                            if (coverImg) {
                                const formData = new FormData();
                                formData.append("cover_image", coverImg, coverImg.name);
                                baseApi.patch('/studios/', formData).then(resp => {
                                    dispatch(setStudio({...studio, ...resp.data}))
                                })
                            }
                            baseApi.patch("/studios/", values).then((resp) => {
                                dispatch(setStudio({
                                    ...studio,
                                    ...resp.data,
                                    preferences: {
                                        ...studio.preferences,
                                        terms_and_conditions: values.preferences.terms_and_conditions
                                    }
                                }))
                                setSuccess(true)
                                setEditName(false)
                                setSubmitting(false)
                            }).catch((err) => {
                                setSuccess(false)
                                setSubmitting(false)
                            })
                        }}>
                        {({isSubmitting}) => (
                            <FormikForm>
                                {renderAlert()}
                                <h3 className="text-muted pt-5 font-size-sm">NOMBRE</h3>
                                {editName ? <Field className="form-control w-50 d-inline" name="name"/>
                                    : <p className="d-inline h3">{studio.name}</p>
                                }
                                <Button
                                    size="sm"
                                    className="d-inline btn btn-icon btn-light-primary bg-white"
                                    onClick={() => setEditName(!editName)}
                                >
                                    <FontAwesomeIcon icon={faPen} size="lg"/>
                                </Button>
                                <div className="d-block pb-10"/>
                                <div className="d-inline-flex">
                                    <div>
                                        <h3 className="text-muted pt-2 font-size-sm">LOGO</h3>
                                        <ImageField src={studio.image} onChange={(file) => setImg(file)}/>
                                    </div>
                                    <div className="pl-10">
                                        <h3 className="text-muted pt-2 font-size-sm">APP COVER</h3>
                                        <ImageField src={studio.cover_image} onChange={(file) => setCoverImg(file)}
                                                    width={240} maxUploadWidth={900} maxUploadHeight={600}
                                                    quality={80}/>
                                    </div>
                                </div>
                                <h3 className="text-muted pt-2 font-size-sm mt-10">REDES SOCIALES</h3>
                                <FormInputGroup name="instagram" label="Instagram" groupText="instagram.com/"
                                                prepend={true} row={false}/>
                                <FieldError name="whatsapp"/>
                                <FormInputGroup name="whatsapp" label="WhatsApp" groupText=""
                                                prepend={true} row={false}/>
                                <label>Términos y Condiciones</label>
                                <Field className="form-control form-control-sm"
                                       name="preferences.terms_and_conditions"/>
                                <FieldError name="preferences.terms_and_conditions"/>
                                <Button className="mt-5" type="submit" disabled={isSubmitting}>Guardar</Button>
                            </FormikForm>
                        )}
                    </Formik>
                </Col>
                <Col className="px-15">
                    <div className="d-flex align-items-center my-5">
                        <h3 className="text-muted pt-2 font-size-sm">SUCURSALES</h3>
                        <div className="d-none">
                            <button onClick={() => setShowCreate(true)}
                                    className="btn btn-light-primary btn-pill ml-3">+
                                Nueva Sucursal
                            </button>
                        </div>
                    </div>
                    {branchesState.branches.map((branch) => {
                        return <div key={branch.id} className="card card-custom shadow mb-3">
                            <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between">
                                    <h6>{branch.name}</h6>
                                    <div>

                                        <Button onClick={() => {
                                            setSelectedBranch(branch)
                                            setShowCreate(true)
                                        }} className="btn btn-icon btn-light-primary btn-sm bg-white">
                                            <FontAwesomeIcon icon={faPen}/>
                                        </Button>
                                        <Button onClick={() => {
                                            setSelectedBranch(branch)
                                            setShowDelete(true)
                                        }} className="btn btn-icon btn-light-danger btn-sm bg-white d-none">
                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                        </Button>
                                    </div>
                                </div>
                                <div className="d-block"/>
                                <span className="pt-3">
                                  <i className="fas fa-map-marker-alt d-inline mr-2"/>
                                  <p className="d-inline">{branch.address.line1} - {branch.address.zip_code} - {branch.address.city} - {branch.address.state}</p>
                                </span>
                                <div className="d-block py-3"/>
                                <span>
                                  <i className="fas fa-phone-alt d-inline mr-2"/>
                                  <p className="d-inline">{branch.phone}</p>
                                </span>
                            </div>
                        </div>

                    })}

                </Col>
            </Row>
        </div>
    );
};

export default Branches;
