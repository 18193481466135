import React from 'react';
import {useState} from 'react';
import {Alert, Button, Modal} from "react-bootstrap";
import {Formik, Form as FormikForm, Field, ErrorMessage} from "formik";
import ReactDatePicker from "react-datepicker";
import {es} from "date-fns/locale";
import {addMinutes, format, formatISO, isAfter} from "date-fns";
import {FormikSelect} from "../../components/form/FormikSelect";
import {useSelector} from "react-redux";
import {StoreState} from "../../reducers";
import {StudioClass} from "../../actions/studioClasses";
import {Room} from "../Toolbox/Rooms";
import baseApi from "../../apis/baseApi";
import {getApiErrorMessage} from "../../utils/apiErrors";
import {Branch} from "../../actions";
import {MinCoach, TimeSlotV2} from "./index";
import yup from "../../utils/yup";
import FieldError from "../../components/form/FieldError";
import {Coach} from "../Coaches";

type Props = {
    show: boolean,
    onHide: Function,
    timeSlots: TimeSlotV2[],
    setTimeSlots: (slots: TimeSlotV2[]) => void
}

const EditMultipleSlotsModal = ({show, onHide, timeSlots, setTimeSlots}: Props) => {
    const [success, setSuccess] = useState<boolean | null>(null);
    const [alertMessage, setAlertMessage] = useState("")
    const classes = useSelector<StoreState, StudioClass[]>(state => state.studioClasses.classes)
    const currentBranch = useSelector<StoreState, Branch>(state => state.currentBranch)
    const rooms = useSelector<StoreState, Room[]>(state => state.rooms.rooms.filter(r => r.branch === currentBranch.id))
    const coaches = useSelector<StoreState, Coach[]>(state => state.coaches.coaches)

    const renderAlert = () => {
        if (success === null) return;

        return <Alert variant={success ? "success" : "danger"}>{alertMessage}</Alert>;
    };

    const handleClose = () => {
        setSuccess(null);
        onHide();
    };

    const validationSchema = yup.object().shape({
        start_filter: yup.date().min(new Date(), "La fecha debe ser mayor a la fecha actual.").required(),
        room_filter: yup.string().nullable(),
        room: yup.string().nullable().test('not-same-as-current', 'No se puede seleccionar el mismo salón', (value, context) => {
            if (value && value === context.parent.room_filter) return false
            return true
        }),
    })

    return (
        <React.Fragment>
            <Modal size="lg" show={show} onHide={handleClose}>
                <Formik
                    validationSchema={validationSchema}
                    validate={(values) => {
                        console.log(values)
                        let errors = {}
                        if (!values.studio_class && !values.room && !values.publish_date) {
                            // @ts-ignore
                            if (!values.coaches || values.coaches?.length === 0){
                                // @ts-ignore
                                errors.general = "Es necesario modificar al menos un valor."
                            }
                        }
                        return errors
                    }}
                    initialValues={{
                        branch_filter: currentBranch.id,
                        start_filter: addMinutes(new Date(), 30),
                        end_filter: undefined as Date | undefined,
                        start_times_filter: [],
                        publish_date: undefined as Date | undefined,
                        room: undefined,
                        coaches: undefined as [string] | undefined,
                        studio_class: undefined as undefined | string,
                        general: null,
                    }}
                    onSubmit={(values, {setSubmitting}) => {
                        setSuccess(null)
                        let body = {
                            ...values,
                            start_filter: formatISO(values.start_filter),
                            end_filter: values.end_filter ? formatISO(values.end_filter) : null,
                            publish_date: values.publish_date ? formatISO(values.publish_date) : null,
                            start_times_filter: values.start_times_filter.length > 0 ? [format(values.start_times_filter[0], "HH:mm:ss")] : [],
                        }
                        const newRoom = values.room ? rooms.find(r => r.id === values.room) : null
                        let newCoaches: null | MinCoach[] = null
                        if (values.coaches && values.coaches?.length >= 1) {
                            newCoaches = coaches.filter(c => values.coaches?.includes(c.id)).map((c) => c as MinCoach)
                        }
                        let studioClassName: string | null = null
                        if (values.studio_class) {
                            studioClassName = classes.find(c => c.id === values.studio_class)?.name ?? ""
                        }
                        baseApi.post("/schedules/update/", body).then(resp => {
                            setAlertMessage("Horarios actualizados.")
                            setSuccess(true)
                            let updatedSlots: string[] = resp.data.slots
                            setTimeSlots(timeSlots.map(t => updatedSlots.includes(t.id)
                                    ? {
                                        ...t,
                                        studio_class: values.studio_class ?? t.studio_class,
                                        studio_class_name: studioClassName ?? t.studio_class_name,
                                        room: newRoom?.id ?? null,
                                        people_limit: newRoom?.people_limit ?? t.people_limit,
                                        coaches: newCoaches ?? t.coaches,
                                        publish_date: values.publish_date ? formatISO(values.publish_date) : t.publish_date
                                        // coaches: values.coaches,
                                    }
                                    : t
                                )
                            )
                            setSubmitting(false)
                        }).catch(err => {
                            setAlertMessage(getApiErrorMessage(err))
                            setSuccess(false)
                            setSubmitting(false)
                        })
                    }}>
                    {({values, isSubmitting, setFieldValue}) => (
                        <FormikForm>
                            <Modal.Header>
                                Editar multiples horarios
                            </Modal.Header>
                            <Modal.Body>
                                {renderAlert()}
                                <h6 className="text-primary">Filtros</h6>
                                <div className="d-inline-flex justify-content-around">
                                    <div>
                                        Modificar horarios a partir del
                                        <div className="">
                                            <div className="customDatePickerWidth">
                                                <ReactDatePicker
                                                    showTimeSelect
                                                    dateFormat="dd 'de' MMMM 'del' yyyy HH:mm"
                                                    locale={es}
                                                    selected={values.start_filter}
                                                    minDate={new Date()}
                                                    onChange={date => {
                                                        if (date) {
                                                            setFieldValue("start_filter", date)
                                                            if (values.end_filter && isAfter(date as Date, values.end_filter)) {
                                                                setFieldValue("end_filter", undefined)
                                                            }
                                                        }
                                                    }}
                                                    customInput={<input className="form-control"/>}
                                                />
                                            </div>
                                        </div>
                                        <FieldError name="start_filter"/>
                                    </div>
                                    <div className="ml-7">
                                        hasta la fecha <span className="text-muted">(opcional)</span>
                                        <div className="">
                                            <div className="customDatePickerWidth">
                                                <ReactDatePicker
                                                    showTimeSelect
                                                    dateFormat="dd 'de' MMMM 'del' yyyy HH:mm"
                                                    locale={es}
                                                    selected={values.end_filter}
                                                    minDate={values.start_filter}
                                                    onChange={date => {
                                                        if (date) {
                                                            setFieldValue("end_filter", date)
                                                        }
                                                    }}
                                                    customInput={<input className="form-control"/>}
                                                />
                                            </div>
                                        </div>
                                        <FieldError name="end_filter"/>
                                    </div>
                                </div>
                                <div className="d-inline-flex justify-content-around pt-4">
                                    {/* FILTRO CLASE */}
                                    <div style={{width: "280px"}}>
                                        donde la clase impartida sea <span className="text-muted">(opcional)</span>
                                        <Field name="studio_class_filter"
                                               placeholder={""}
                                               isClearable={true}
                                               options={classes.map((c) => ({label: c.name, value: c.id}))}
                                               component={FormikSelect}/>
                                    </div>

                                    {/*FILTRO SALON*/}
                                    <div className="ml-7" style={{width: "280px"}}>
                                        y el salón de la clase sea <span className="text-muted">(opcional)</span>
                                        <Field name="room_filter"
                                               placeholder=""
                                               isClearable={true}
                                               options={rooms.map((r) => ({value: r.id, label: r.name}))}
                                               component={FormikSelect}/>
                                    </div>
                                </div>
                                <div className="d-inline-flex justify-content-around pt-4">
                                    {/* FILTRO HORA */}
                                    <div style={{width: "280px"}}>
                                        <div> y la hora de inicio sea <span className="text-muted">(opcional)</span>
                                        </div>
                                        <div className="">
                                            <ReactDatePicker
                                                showTimeSelect
                                                showTimeSelectOnly
                                                minTime={new Date(2024, 1, 1, 0, 0)}
                                                maxTime={new Date(2024, 1, 1, 23, 59)}
                                                locale={es}
                                                selected={values.start_times_filter ? values.start_times_filter[0] : null}
                                                onChange={date => {
                                                    if (date) {
                                                        setFieldValue(`start_times_filter`, [date])
                                                    } else {
                                                        setFieldValue("start_times_filter", [])
                                                    }
                                                }}
                                                timeFormat="h:mm aa"
                                                dateFormat="h:mm aa"
                                                timeIntervals={5}
                                                timeCaption={"Hora"}
                                                isClearable={true}
                                                customInput={<input
                                                    className="form-control "/>}
                                            />
                                        </div>
                                    </div>
                                    {/* FILTRO WEEKDAY */}
                                    <div className="ml-7">
                                        <div> y el dia de la semana sea <span className="text-muted">(opcional)</span>
                                        </div>
                                        <div className="" style={{width: "280px"}}>
                                            <Field name="weekday_filter"
                                                   isClearable={true}
                                                   options={[
                                                       {value: "1", label: "Lunes"},
                                                       {value: "2", label: "Martes"},
                                                       {value: "3", label: "Miércoles"},
                                                       {value: "4", label: "Jueves"},
                                                       {value: "5", label: "Viernes"},
                                                       {value: "6", label: "Sábado"},
                                                       {value: "7", label: "Domingo"},
                                                   ]}
                                                   component={FormikSelect}
                                                // isMulti={true}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <h6 className="text-primary">Modificaciones</h6>
                                    <div className="text-danger font-weight-bold font-size-lg">
                                        <ErrorMessage name="general" />
                                    </div>
                                    {/*  NUEVA CLASE  */}
                                    <div className="pt-2">
                                        <div style={{width: ""}} className=" ">
                                            <div>
                                                <span className="">Nueva Clase</span> <span
                                                className="text-muted">(opcional)</span>
                                            </div>
                                            <div className="customDatePickerWidth" style={{width: "250px"}}>
                                                <Field name="studio_class"
                                                       placeholder={""}
                                                       isClearable={true}
                                                       options={classes.map((c) => ({label: c.name, value: c.id}))}
                                                       component={FormikSelect}/>
                                            </div>
                                            <FieldError name="studio_class"/>
                                        </div>
                                    </div>
                                    {/* NUEVO SALON */}
                                    <div style={{width: "260px"}}>
                                        Nuevo salón <span className="text-muted">(opcional)</span>
                                        <Field name="room"
                                               isClearable={true}
                                               options={rooms.map((r) => ({
                                                   value: r.id,
                                                   label: `${r.name} (${r.people_limit} lugares)`
                                               }))}
                                               component={FormikSelect}/>
                                        <FieldError name="room"/>
                                    </div>
                                    {/*  NEW COACHES  */}
                                    <div className="pt-2">
                                        <div style={{width: ""}} className=" ">
                                            <div>
                                                <span className="">Nuevo Coach</span> <span
                                                className="text-muted">(opcional)</span>
                                            </div>
                                            <div className="customDatePickerWidth" style={{width: "250px"}}>
                                                <Field name="coaches"
                                                    // isDisabled={disabled}
                                                       options={coaches.map((c) => ({label: c.full_name, value: c.id}))}
                                                       component={FormikSelect}
                                                       isMulti={true}
                                                />
                                            </div>
                                            <FieldError name="coaches"/>
                                        </div>
                                    </div>

                                    {/*  FECHA DE PUBLICACION  */}
                                    <div className="pt-2">
                                        <div style={{width: ""}} className=" ">
                                            <div>
                                                <span className="">Publicar horarios el</span> <span
                                                className="text-muted">(opcional)</span>
                                            </div>
                                            <div className="customDatePickerWidth" style={{width: "250px"}}>
                                                <ReactDatePicker
                                                    // disabled={disabled}
                                                    dateFormat="dd 'de' MMMM 'del' yyyy, HH:mm"
                                                    locale={es}
                                                    showTimeSelect={true}
                                                    timeCaption={"Hora"}
                                                    selected={values.publish_date}
                                                    minDate={new Date()}
                                                    isClearable={true}
                                                    timeIntervals={15}
                                                    onChange={date => {
                                                        setFieldValue("publish_date", date)
                                                    }}
                                                    customInput={<input className="form-control"/>}
                                                />
                                            </div>
                                            <FieldError name="publish_date"/>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="outline-secondary" onClick={handleClose}>
                                    Cerrar
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "..." : "Confirmar"}
                                </Button>
                            </Modal.Footer>
                        </FormikForm>
                    )}
                </Formik>
            </Modal>
        </React.Fragment>
    );
};

export default EditMultipleSlotsModal;
